import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import { styled, withTheme } from "@material-ui/styles";
import SpeechBubble from "./SpeechBubble";
import SpeechBubbleReply from "./SpeechBubbleReply";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { dialogueOptions } from "../Assets/Dialogues";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setShowProjects: (boolean) => dispatch({ type: "SET_SHOW_PROJECTS", payload: boolean }),
  setShowContact: (boolean) => dispatch({ type: "SET_SHOW_CONTACT", payload: boolean }),
  setCurrDialogue: (data) => dispatch({ type: "SET_CURR_DIALOGUE", payload: data }),
  setPrevDialogue: (data) => dispatch({ type: "SET_PREV_DIALOGUE", payload: data }),
});

const SpeechController = ({ app, setCurrDialogue, setShowProjects, setShowContact }) => {
  const [showBubble, setShowBubble] = useState(true);

  const updateDialogue = async () => {
    if (app.currDialogue.nextDialogueID) {
      const nextDialogue =
        dialogueOptions[dialogueOptions.findIndex((dialogue) => dialogue._id === app.currDialogue.nextDialogueID)];
      setCurrDialogue(nextDialogue);
      await setShowBubble(false);
    }
  };

  const updateDialogue_response = async (nextDialogueID) => {
    setCurrDialogue(dialogueOptions[dialogueOptions.findIndex((dialogue) => dialogue._id === nextDialogueID)]);
    await setShowBubble(false);
  };

  useEffect(() => {
    if (!showBubble)
      setTimeout(() => {
        setShowBubble(true);
      }, 350);
  }, [showBubble]);

  useEffect(() => {
    if (app.currDialogue._id === 8 && !app.showProjectPanel) {
      setShowProjects(true);
    } else {
      setShowProjects(false);
    }
    if (app.currDialogue._id === 9 && !app.showContactPanel) {
      setShowContact(true);
    } else {
      setShowContact(false);
    }
  }, [app.currDialogue]);

  useEffect(() => {
    if (!app.showProjectPanel) {
      setShowBubble(false);
      setTimeout(() => {
        setShowBubble(true);
      }, 350);
    }
  }, [app.showProjectPanel, app.showContactPanel]);

  return (
    <SpeechBubbleWrap className={!app.currDialogue.responses ? "spacer" : ""}>
      <AnimatePresence>
        {showBubble && (
          <Grid item xs={12}>
            <motion.div
              exit={{ scale: [1, 0.9, 0], opacity: [1, 0, 0, 0], y: [0, 120, 120] }}
              transition={{ ease: "easeInOut", duration: 0.3 }}
              style={{ marginBottom: 20 }}
            >
              <SpeechBubble
                show={false}
                data={app.currDialogue}
                showBubble={showBubble}
                updateDialogue={updateDialogue}
              />
            </motion.div>
          </Grid>
        )}
      </AnimatePresence>
      <ResponseContainer container spacing={3}>
        {app.currDialogue.responses &&
          app.currDialogue.responses.map((response, index) => (
            <Grid item xs={12} md={4}>
              <SpeechBubbleReply
                key={index}
                delay={index / 5 + 1}
                showBubble={showBubble}
                data={response}
                isResponse={true}
                updateDialogue={() => updateDialogue_response(response.nextDialogueID)}
              />
            </Grid>
          ))}
      </ResponseContainer>
    </SpeechBubbleWrap>
  );
};

const SpeechBubbleWrap = styled(withTheme(Box))((props) => ({
  position: "absolute",
  left: 0,
  right: 0,
  bottom: "45%",
  margin: "0 auto",
  zIndex: 10,
  maxWidth: 1200,
  width: "100%",
  transition: "bottom 0.25s ease, transform 0.25s ease",
  [props.theme.breakpoints.down("lg")]: {
    maxWidth: 1000,
  },
  [props.theme.breakpoints.down("sm")]: {
    bottom: "38%",
    maxWidth: "80%",
  },
  [props.theme.breakpoints.down("xs")]: {
    maxWidth: "90%",
    bottom: "5%",
    /*    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column", */
    "&.spacer": {
      minHeight: 250,
    },
  },
  "& p": {
    marginTop: 10,
    textAlign: "right",
    fontSize: 12,
    color: "#2d2014",
    fontWeight: 700,
  },
}));

const ResponseContainer = styled(withTheme(Grid))((props) => ({
  justifyContent: "center",
}));

export default connect(mapStateToProps, mapDispatchToProps)(SpeechController);
