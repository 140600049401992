import { createStore, combineReducers, compose } from "redux";
import appReducer from "../Reducers/app";
import errorsReducer from "../Reducers/errors";

const composedEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const initialState = {
  app: {
    loading: false,
    characterState: 0,
    currDialogue: {
      _id: 0,
      text: "Hello! I'm Aaron Rizhik, a full stack developer from London.",
      nextDialogueID: 1,
      showContinue: true,
    },
    prevDialogue: {},
    showProjectsPanel: false,
    showContactPanel: false,
  },
  errors: {
    show: false,
    errors: [],
  },
};

const reducerMerge = combineReducers({
  app: appReducer,
  errors: errorsReducer,
});

export default function configureStore() {
  return createStore(
    reducerMerge,
    initialState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
}
