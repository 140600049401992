import React from "react";
import { motion } from "framer-motion";
import { styled, withTheme } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

function Title() {
  return (
    <TitleContainer>
      <Typography
        component={motion.h2}
        animate={{ y: [-200, 5, 0, 0] }}
        transition={{ ease: "easeInOut", duration: 1 }}
        variant="h2"
      >
        Aaron Rizhik
      </Typography>
      <motion.div
        component={motion.h2}
        animate={{ x: [-600, 5, 0, 0] }}
        transition={{ ease: "easeInOut", duration: 1 }}
        className="title-container"
      >
        <Typography variant="h6">Full Stack Developer</Typography>
      </motion.div>
    </TitleContainer>
  );
}

const TitleContainer = styled(withTheme(Box))((props) => ({
  position: "relative",
  zIndex: 9,
  padding: 50,
  marginBottom: 20,
  [props.theme.breakpoints.down("sm")]: {
    padding: 20,
  },
  "& h2": {
    color: "#fcfcfc",
    fontWeight: 700,
    letterSpacing: -1,
    marginBottom: 10,
    textShadow:
      "10px 9px 0px rgba(0,0,0,0.65), -4px -4px rgba(0,0,0,0.65), 0px 3px 0px rgba(0,0,0,0.65), -4px 5px rgba(0,0,0,0.65), 8px -4px 0px rgba(0,0,0,0.65), 17px 0px 0px rgba(0,0,0,0.65), 16px 16px 3px rgba(45,32,25,0.25)",
    [props.theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  "& h6": {
    color: "#fcfcfc",
    transition: "font-size 0.25s ease",
    letterSpacing: -0.5,
    [props.theme.breakpoints.down("sm")]: {
      fontSize: 14,
    },
  },

  "& .title-container": {
    /*   backgroundColor: "rgba(0,0,0,0.65)", */
    padding: "10px 30px 10px 30px",
    borderRadius: "2rem",
    display: "inline-block",
    marginLeft: -5,
    backgroundColor: "rgba(0,0,0,0.65)",
    border: "3px solid rgba(45, 32, 20,0.25)",
    /*   borderTopWidth: "1px",
    borderLeftWidth: "1px", */
    backdropFilter: "blur(8px)",
    [props.theme.breakpoints.down("sm")]: {
      padding: "10px 24px 10px 24px",
    },
  },
}));

export default Title;
