const dialogueOptions = [
  {
    _id: 0,
    text: "Hello! I'm Aaron, a full stack developer from London.",
    nextDialogueID: 1,
    showContinue: true,
  },
  {
    _id: 1,
    text: "How may I help you today?",
    responses: [
      { _id: 2, text: "I'd like to talk!", nextDialogueID: 5 },
      { _id: 3, text: "I'd like to see your work!", nextDialogueID: 6 },
      { _id: 4, text: "I'd like to contact you!", nextDialogueID: 7 },
    ],
  },
  {
    _id: 5,
    text: "What would you like to do?",
    responses: [
      { _id: 10, text: "Ask a question", nextDialogueID: 13 },
      { _id: 11, text: "Tell a joke", nextDialogueID: 14 },
      { _id: 12, text: "Nevermind", nextDialogueID: 1 },
    ],
  },
  {
    _id: 6,
    text: "Of course! Let me get those for you...",
    nextDialogueID: 8,
    showContinue: true,
  },
  {
    _id: 7,
    text: "One moment please...",
    nextDialogueID: 9,
    showContinue: true,
  },
  { _id: 8, text: "Here they are!" },
  { _id: 9, text: "Here you go!" },
  {
    _id: 13,
    text: "What would you like to ask?",
    responses: [
      { _id: 10, text: "What are your hobbies?", nextDialogueID: 16 },
      { _id: 11, text: "What is the meaning of life?", nextDialogueID: 17 },
      { _id: 12, text: "How are you today?", nextDialogueID: 18 },
    ],
  },
  {
    _id: 16,
    text: "I enjoy things like cooking, photography and gaming!",
    nextDialogueID: 5,
    showContinue: true,
  },
  {
    _id: 17,
    text: "You ask a deep question, the meaning of life is... hmm, maybe another time.",
    nextDialogueID: 5,
    showContinue: true,
  },
  {
    _id: 18,
    text: "To be honest, quite tired, I feel like I have been walking for days!",
    nextDialogueID: 5,
    showContinue: true,
  },
  {
    _id: 14,
    text: "Which joke would you like to hear?",
    responses: [
      { _id: 10, text: "The knock knock one", nextDialogueID: 19 },
      { _id: 11, text: "The one about functions", nextDialogueID: 20 },
      { _id: 12, text: "The one about dates", nextDialogueID: 21 },
    ],
  },
  {
    _id: 19,
    text: "Knock Knock...",
    nextDialogueID: 22,
    showContinue: true,
  },
  {
    _id: 22,
    text: "Race Condition.",
    responses: [{ _id: 10, text: "Who's there?", nextDialogueID: 5 }],
  },
  {
    _id: 20,
    text: "Why did the functions apply for a divorce?",
    responses: [{ _id: 10, text: "Why?", nextDialogueID: 23 }],
  },
  {
    _id: 23,
    text: "Because they had constant arguments.",
    nextDialogueID: 5,
    showContinue: true,
  },
  {
    _id: 21,
    text: "Why do programmers confuse Christmas with Halloween?",
    responses: [{ _id: 10, text: "Why?", nextDialogueID: 24 }],
  },
  {
    _id: 24,
    text: "Because Oct 31 == Dec 25.",
    nextDialogueID: 5,
    showContinue: true,
  },
];

export { dialogueOptions };
