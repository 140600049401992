import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { styled, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SingleProject from "./SingleProject";
import SynergyOfSerra from "../Assets/Projects/Sos.png";
import Nomyos from "../Assets/Projects/Nomyos.png";
import Portfolio from "../Assets/Projects/Portfolio_1.png";
import UKPN from "../Assets/Projects/UKPN.png";
import Instagram from "../Assets/Projects/Instagram.png";
import Weather from "../Assets/Projects/Weather.png";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setShowProjects: (boolean) => dispatch({ type: "SET_SHOW_PROJECTS", payload: boolean }),
  setShowContact: (boolean) => dispatch({ type: "SET_SHOW_CONTACT", payload: boolean }),
});

const projects = [
  {
    name: "Synergy of Serra Game Client",
    img: SynergyOfSerra,
    url: "https://synergyofserra.com/",
    /*  feRepo: "private", */
  },
  {
    name: "Your Local Net Zero Hub",
    img: UKPN,
    url: "https://www.yourlocalnetzerohub.co.uk/",
    /*  feRepo: "private", */
  },
  {
    name: "Nomyos",
    img: Nomyos,
    url: "https://www.nomyos.com/",
    /*  feRepo: "private", */
  },
  {
    name: "Portfolio",
    img: Portfolio,
    url: "https://aaronrizhik.com/",
    /*  feRepo: "private", */
    /*    beRepo: false, */
  },
  /*   {
    name: "Instagram Clone",
    img: Instagram,
    url: "https://instagram-clone-fe.vercel.app/",
    feRepo: "https://github.com/arzhk/Build-Week-Instagram-Clone-FE",
    beRepo: "https://github.com/arzhk/Build-Week-Instagram-Clone-BE",
  },
  {
    name: "Weather App",
    img: Weather,
    url: "https://arzhk-weather-app.vercel.app/",
    feRepo: "https://github.com/arzhk/M7-D10-Weather-App",
    beRepo: "https://github.com/arzhk/M8-D10-Weather-App-BE",
  }, */
];

function ProjectsPanel({ show, setShowProjects, resetHandler }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ opacity: [1, 0, 0, 0], y: [0, 120, 120] }}
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0 }}
        >
          <PanelContainer
            component={motion.div}
            animate={{ opacity: [0, 0, 0, 1], y: [120, 120, 0] }}
            transition={{ ease: "easeInOut", duration: 0.5, delay: 1.5 }}
          >
            <TitleBar>
              <Typography variant="h5">Projects</Typography>
              <Button
                onClick={() => {
                  resetHandler();
                  setShowProjects(false);
                }}
              >
                <span className="text">X</span>
              </Button>
            </TitleBar>
            <Typography
              variant="body1"
              style={{
                color: "#fcfcfc",
                marginBottom: 15,
                fontSize: "0.8rem",
                letterSpacing: -0.5,
                textAlign: "center",
              }}
            >
              Private repos are available upon request.
            </Typography>
            <Grid container spacing={3}>
              {projects.map((project, index) => (
                <Grid key={index} item xs={12}>
                  <SingleProject data={project} delay={index / 7 + 2} />
                </Grid>
              ))}
            </Grid>
          </PanelContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const PanelContainer = styled(withTheme(Box))((props) => ({
  backgroundColor: "rgba(0,0,0,0.65)",
  borderRadius: "3rem",
  padding: "35px 40px",
  border: "2px solid rgba(45, 32, 20,0.25)",
  borderTopWidth: "1px",
  borderLeftWidth: "1px",
  position: "relative",
  zIndex: 12,
  width: "100%",
  maxHeight: 1200,
  overflow: "auto",
  maxWidth: 1200,
  margin: "0 auto",
  transition: "transform 0.25s ease, background-color 0.25s ease",
  backdropFilter: "blur(4px)",
  boxShadow:
    "2px 2px 0px rgba(45, 32, 20,0.25), -2px -2px 0px rgba(45, 32, 20,0.25), -2px 2px 0px rgba(45, 32, 20,0.25), 2px -2px 0px rgba(45, 32, 20,0.25)",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "2rem",
  },
  [props.theme.breakpoints.down("sm")]: {
    borderRadius: 0,
    padding: "35px 10px",
    position: "absolute",
    top: 0,
    bottom: 0,
    maxHeight: "100vh",
  },
}));

const TitleBar = styled(withTheme(Box))((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 15,
  "& h5": {
    color: "#fcfcfc",
    backgroundColor: "rgba(45,32,20,0.75)",
    padding: "20px 40px",
    borderRadius: "3rem",
    [props.theme.breakpoints.down("sm")]: {
      marginLeft: -40,
      padding: "20px 60px 20px 50px",
      borderRadius: "0rem 3rem 3rem 0rem",
    },
  },
  "& button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: "100%",
    height: 64,
    width: 64,
    color: "#fcfcfc",
    "& .text": {
      marginBottom: -4,
      marginRight: -2,
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsPanel);
