import React from "react";
import { styled, withTheme } from "@material-ui/styles";
import { motion } from "framer-motion";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import GitHubIcon from "@material-ui/icons/GitHub";
import PublicIcon from "@material-ui/icons/Public";

function SingleProject({ data, delay }) {
  return (
    <ProjectContainer
      component={motion.div}
      animate={{ scale: [0, 1], opacity: [0, 1], y: [-220, 10, 0] }}
      transition={{ ease: "easeInOut", duration: 0.5, delay: delay }}
    >
      <ProjectName variant="h6">{data.name}</ProjectName>
      <ButtonBar>
        <a href={`${data.url}`} target="_blank" rel="noreferrer">
          <Button>
            <PublicIcon style={{ marginRight: 6 }} />
            <span style={{ marginTop: 3 }}> Live</span>
          </Button>
        </a>
        {data.feRepo &&
          (data.feRepo === "private" ? (
            <Button disabled={data.feRepo === "private"}>
              <GitHubIcon style={{ marginRight: 8 }} />
              <span className="large-screen" style={{ marginTop: 3 }}>
                Private
              </span>
            </Button>
          ) : (
            <a href={`${data.feRepo}`} target="_blank" rel="noreferrer">
              <Button>
                <GitHubIcon style={{ marginRight: 8 }} />
                <span className="large-screen" style={{ marginTop: 3 }}>
                  Frontend
                </span>
              </Button>
            </a>
          ))}
        {data.beRepo &&
          (data.beRepo === "private" ? (
            <Button disabled={data.beRepo === "private"}>
              <GitHubIcon style={{ marginRight: 8 }} />
              <span className="large-screen" style={{ marginTop: 3 }}>
                Private
              </span>
            </Button>
          ) : (
            <a href={`${data.beRepo}`} target="_blank" rel="noreferrer">
              <Button>
                <GitHubIcon style={{ marginRight: 8 }} />
                <span className="large-screen" style={{ marginTop: 3 }}>
                  Backend
                </span>
              </Button>
            </a>
          ))}
      </ButtonBar>
      {data.img && <img src={data.img} alt="project-main" />}
    </ProjectContainer>
  );
}

const ProjectContainer = styled(withTheme(Box))((props) => ({
  borderRadius: "0rem 2rem 0rem 2rem",
  borderTop: "1px solid  rgba(255,255,255,0.28)",
  borderLeft: "1px solid  rgba(255,255,255,0.28)",
  borderRight: "1px solid  rgba(0,0,0,0.01)",
  borderBottom: "1px solid  rgba(0,0,0,0.01)",
  overflow: "hidden",
  maxHeight: 200,
  minHeight: 200,
  backgroundColor: "rgba(0,0,0,0.65)",
  position: "relative",
  "& img": {
    objectFit: "cover",
    width: "100%",
    borderRadius: "0rem 2rem 0rem 2rem",
    minHeight: "200px",
  },
  "&::before": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(0deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 10%)",
    zIndex: 0,
  },
  "&::after": {
    content: `""`,
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(180deg, rgba(0,0,0,0.25) 0%, rgba(0,0,0,0) 10%)",
    zIndex: 0,
  },
}));

const ProjectName = styled(Typography)({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.65)",
  padding: "14px 40px 14px 30px",
  color: "#fcfcfc",
  borderRadius: "0rem 0rem 2rem 0rem",
  textShadow:
    "3px 4px #2d2014, -2px -2px #2d2014, 5px 0px #2d2014,5px 0px #2d2014, 0px 5px #2d2014, -2px 3px #2d2014, 4px -2px #2d2014, 3px -3px 0px #2d2014, 3px 3px 6px #2d2014  ",
});

const ButtonBar = styled(withTheme(Box))((props) => ({
  position: "absolute",
  bottom: 0,
  right: 0,
  zIndex: 2,
  backgroundColor: "rgba(0,0,0,0.65)",
  borderRadius: "1rem 0rem 0rem 0rem",
  overflow: "hidden",
  "& a": {
    textDecoration: "none",
  },
  "& button": {
    backgroundColor: "transparent",
    padding: "10px 20px",
    color: "#fcfcfc",
    fontSize: 12,
    fontWeight: 100,
    lineHeight: 1,
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.65)",
    },
  },
  "& .large-screen": {
    [props.theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default SingleProject;
