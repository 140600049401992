import React, { useEffect } from "react";
import { connect } from "react-redux";
import { styled } from "@material-ui/styles";
import { dialogueOptions } from "./Assets/Dialogues";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import SpeechController from "./Components/SpeechController";
import Title from "./Components/Title";
import Character from "./Components/Character";
import Terrain from "./Components/Terrain";
import Sky from "./Components/Sky";
import ProjectsPanel from "./Components/ProjectsPanel";
import ContactPanel from "./Components/ContactPanel";
import SocialButtons from "./Components/SocialButtons";
import Background from "./Components/Background";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setCurrDialogue: (data) => dispatch({ type: "SET_CURR_DIALOGUE", payload: data }),
  setPrevDialogue: (data) => dispatch({ type: "SET_PREV_DIALOGUE", payload: data }),
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Main = (props) => {
  const { showProjectPanel, showContactPanel } = props.app;

  const resetHandler = () => {
    const firstDialogue = dialogueOptions[dialogueOptions.findIndex((dialogue) => dialogue._id === 1)];
    props.setCurrDialogue(firstDialogue);
  };

  const testQuery = async () => {
    try {
      const response = await fetch("https://pyindexer-production-a2ad.up.railway.app/a", {
        method: "POST",
        body: JSON.stringify({ query: "What is your job and do you have any pets?" }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();

      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    testQuery();
  }, []);

  return (
    <PageWrap>
      <PageContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Title />
          </Grid>
          <Grid item xs={12}>
            <ProjectsPanel show={showProjectPanel} resetHandler={resetHandler} />
          </Grid>
          <Grid item xs={12}>
            <ContactPanel show={showContactPanel} resetHandler={resetHandler} />
          </Grid>
          <Grid item xs={12}>
            <SpeechController />
          </Grid>
          <Grid item xs={12}>
            <SocialButtons />
            <Character />
            <Sky />
            <Background />
            <Terrain />
          </Grid>
        </Grid>
      </PageContainer>
    </PageWrap>
  );
};

const PageWrap = styled(Box)({
  minHeight: "100vh",
  width: "100%",
  overflow: "hidden",
});

const PageContainer = styled(Box)({
  minHeight: "100vh",
  overflow: "hidden",
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
