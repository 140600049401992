import React from "react";
import { styled, makeStyles } from "@material-ui/styles";
import BackgroundImage from "../Assets/bg.jpg";

import Box from "@material-ui/core/Box";

function Background() {
  const classes = styles();
  return (
    <BackgroundContainer>
      <img src={BackgroundImage} className={classes.tile} style={{ background: `url(${BackgroundImage})` }}></img>
    </BackgroundContainer>
  );
}

const styles = makeStyles((theme) => ({
  "@keyframes animateSky": {
    from: { transform: "translate3d(0,0,0)" },
    to: { transform: "translate3d(-2856px,0,0)" },
  },
  tile: {
    /*   animation: "$animateSky 900s linear infinite",
    backgroundRepeat: "repeat-x", */
    width: "100%",
    height: "100vh",
    objectFit: "cover",
  },
}));

const BackgroundContainer = styled(Box)({
  position: "fixed",
  top: " -10%",
  left: 0,
  right: 0,
  zIndex: -5,
  overflow: "hidden",
});

export default Background;
