import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import CharacterRunning from "../Assets/Character/walkanim_transparent-3x.png";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setError: (error) => dispatch({ type: "SET_ERROR", payload: error }),
  showErrors: (boolean) => dispatch({ type: "DISPLAY_ERRORS", payload: boolean }),
});

const Character = (props) => {
  const classes = styles();
  return (
    <Box className={classes.charWrap}>
      <Box className={classes.characterContainer}>
        <img src={CharacterRunning} className={classes.character} alt="character-running" draggable={false} />
      </Box>
      <Box className={classes.characterShadowContainer}>
        <img src={CharacterRunning} className={classes.characterShadow} alt="character-running" draggable={false} />
      </Box>
    </Box>
  );
};

const styles = makeStyles((theme) => ({
  "@keyframes animateCharacter": {
    from: { transform: "translate3d(0,0,0)" },
    to: { transform: "translate3d(-100%,0,0)" },
  },

  characterContainer: {
    width: "calc(64px * 8)",
    height: "calc(64px * 8 )",
    overflow: "hidden",
    position: "fixed",
    bottom: 170,
    zIndex: 9,
    transition: "width 0.35s ease",
    [theme.breakpoints.down("sm")]: {
      width: "calc(64px * 6)",
      height: "calc(64px * 6)",
    },
    /*    "&::before": {
      content: `""`,
      position: "absolute",
      left: "25%",
      right: "25%",
      bottom: 20,
      height: 36,
      zIndex: -1,
      backgroundColor: "rgba(0,0,0,0.15)",
      filter: "blur(2px)",
      borderRadius: "100%",
      [theme.breakpoints.down("sm")]: {
        left: "30%",
        right: "30%",
        bottom: 14,
        height: 36,
      },
    }, */
  },
  characterShadowContainer: {
    width: "calc(44px * 8)",
    height: "calc(64px * 8 )",
    overflow: "hidden",
    position: "fixed",
    bottom: 15,
    left: -370,
    right: 0,
    margin: "0 auto",
    zIndex: 8,
    transform: "rotate(-60deg) scale(0.65)",
    transition: "width 0.35s ease",
    filter: "blur(15px) brightness(0)",
    opacity: 0.5,
    [theme.breakpoints.down("sm")]: {
      width: "calc(64px * 6)",
      height: "calc(64px * 6)",
      left: -240,
      bottom: 85,
    },
  },
  character: {
    width: "calc(64px * 64)",
    animation: "$animateCharacter 1s steps(8) infinite",
    imageRendering: "pixelated",
    transition: "width 0.25s ease",
    userSelect: "none",
    [theme.breakpoints.down("sm")]: {
      width: "calc(64px * 48)",
    },
  },
  characterShadow: {
    width: "calc(64px * 64)",
    animation: "$animateCharacter 1s steps(8) infinite",
    imageRendering: "pixelated",
    transition: "width 0.25s ease",
    userSelect: "none",
    zIndex: 10,
    [theme.breakpoints.down("sm")]: {
      width: "calc(64px * 48)",
    },
  },
  charWrap: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(Character);
