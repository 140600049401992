import React, { useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import { styled } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import TypeWriterText from "./TypeWriterText";

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setCurrDialogue: (data) => dispatch({ type: "SET_CURR_DIALOGUE", payload: data }),
  setPrevDialogue: (data) => dispatch({ type: "SET_PREV_DIALOGUE", payload: data }),
});

function SpeechBubbleReply({ data, showBubble, delay = 0.35, updateDialogue }) {
  const [finished, setFinished] = useState(false);

  return (
    <>
      {showBubble && (
        <>
          <SpeechBubbleContainer
            component={motion.div}
            animate={{ y: [100, 100, 0], opacity: [0, 0, 0, 1] }}
            transition={{
              ease: "easeInOut",
              duration: 0.5,
              delay: delay,
            }}
            onClick={updateDialogue}
          >
            <TypeWriterText text={data.text} setFinished={setFinished} />
          </SpeechBubbleContainer>
          {data.showContinue && finished && (
            <motion.div
              animate={{ opacity: [0, 1] }}
              transition={{ ease: "easeInOut", duration: 0.15 }}
              exit={{ opacity: [1, 0] }}
              style={{ opacity: 0 }}
            >
              <Typography
                component={motion.p}
                animate={{ opacity: [0.5, 1, 0.5] }}
                transition={{ ease: "easeInOut", duration: 2, repeat: "Infinity" }}
                variant="body1"
              >
                {data.continueText || "CLICK TO CONTINUE..."}
              </Typography>
            </motion.div>
          )}
        </>
      )}
    </>
  );
}

const SpeechBubbleContainer = styled(Box)({
  padding: "30px 30px",
  border: "2px solid rgba(45, 32, 20,0.25)",
  borderTopWidth: "1px",
  borderLeftWidth: "1px",
  borderRadius: "1rem 1rem 1rem 0rem",
  backgroundColor: "rgba(255,255,255,0.95)",
  color: "#454545",
  position: "relative",
  lineHeight: 1.5,
  cursor: "pointer",
  opacity: 0,
  width: "100%",
  maxWidth: 500,
  /*  minHeight: 111, */
  margin: "0 auto",
  transition: "transform 0.25s ease, background-color 0.25s ease",
  backdropFilter: "blur(4px)",
  boxShadow: "2px 4px 0px rgba(45, 32, 20,0.25)",
  "&:hover": {
    transform: "scale(1.05) !important",
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SpeechBubbleReply);
