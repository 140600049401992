export default function a(state = {}, action) {
  switch (action.type) {
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    case "SET_CHAR_STATE":
      return {
        ...state,
        characterState: action.payload,
      };
    case "SET_CURR_DIALOGUE":
      return {
        ...state,
        currDialogue: action.payload,
      };
    case "SET_PREV_DIALOGUE":
      return {
        ...state,
        prevDialogue: action.payload,
      };
    case "SET_SHOW_PROJECTS":
      return {
        ...state,
        showProjectPanel: action.payload,
      };
    case "SET_SHOW_CONTACT":
      return {
        ...state,
        showContactPanel: action.payload,
      };

    default:
      return state;
  }
}
