import React from "react";
import { motion } from "framer-motion";
import { styled } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const colors = ["rgba(10,102,194,0.5)", "rgba(29,161,242,0.5)", "rgba(255,0,0,0.5)"];

function SingleSocial({ data, index, delay }) {
  return (
    <Wrap
      component={motion.div}
      animate={{ y: [100, 100, 0], opacity: [0, 0, 0, 1] }}
      transition={{
        ease: "easeInOut",
        duration: 0.5,
        delay: delay,
      }}
    >
      <a href={`${data.url}`} target="_blank" rel="noreferrer">
        <SocialContainer style={{ backgroundColor: colors[index] }}>
          {data.icon}
          <Typography variant="h6">{data.name}</Typography>
        </SocialContainer>
      </a>
    </Wrap>
  );
}

const Wrap = styled(Box)({
  "& a": {
    textDecoration: "none",
  },
});

const SocialContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 14,
  borderRadius: "0rem 2rem 0rem 2rem",
  transition: "transform 0.25s ease",

  "& h6": {
    marginLeft: 10,
    color: "#fcfcfc",
  },
  "& svg": {
    color: "#fcfcfc",
    fontSize: "2.5rem",
  },
  "&:hover": {
    transform: "scale(1.05)",
  },
});

export default SingleSocial;
