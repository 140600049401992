import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriterText({ text, setFinished }) {
  return (
    <Typewriter
      onInit={(typewriter) => {
        typewriter
          .typeString(`${text}`)
          .callFunction(() => {
            setTimeout(() => {
              setFinished(true);
            }, 500);
          })
          .start();
      }}
      options={{
        delay: 35,
        cursor: "",
      }}
    />
  );
}

export default TypeWriterText;
