import React from "react";
import { motion } from "framer-motion";
import { styled, makeStyles } from "@material-ui/styles";
/* import TerrainBackground from "../Assets/Terrain.png"; */
import TerrainBackground from "../Assets/TerrainNewTest.png";
import Box from "@material-ui/core/Box";

function Terrain() {
  const classes = styles();

  return (
    <TerrainContainer
      component={motion.div}
      animate={{ y: [200, 5, 0, 0] }}
      transition={{ ease: "easeInOut", duration: 1 }}
    >
      <Box className={classes.tile} style={{ background: `url(${TerrainBackground})` }}></Box>
    </TerrainContainer>
  );
}

const styles = makeStyles((theme) => ({
  "@keyframes animateTerrain": {
    from: { transform: "translate3d(0,0,0)" },
    to: { transform: "translate3d(-1683px,0,0)" },
  },
  tile: {
    animation: "$animateTerrain 10s linear infinite",
    backgroundRepeat: "repeat-x",
    width: 6732,
    height: 494,
  },
}));

const TerrainContainer = styled(Box)({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: -2,
  overflow: "hidden",
});

export default Terrain;
