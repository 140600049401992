import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { styled, withTheme } from "@material-ui/styles";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import SingleSocial from "../Components/SingleSocial";
/* import ContactForm from "../Components/ContactForm"; */
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
/* import YouTubeIcon from "@material-ui/icons/YouTube"; */

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  setShowProjects: (boolean) => dispatch({ type: "SET_SHOW_PROJECTS", payload: boolean }),
  setShowContact: (boolean) => dispatch({ type: "SET_SHOW_CONTACT", payload: boolean }),
});

const socials = [
  { name: "LinkedIn", url: "https://www.linkedin.com/in/aaronrizhik", icon: <LinkedInIcon /> },
  { name: "Twitter", url: "https://twitter.com/_arzhk", icon: <TwitterIcon /> },
  /*  { name: "YouTube", url: "https://www.youtube.com/channel/UCBKDg4WhX2ouHvXf_MwD2LA", icon: <YouTubeIcon /> }, */
];

function ContactPanel({ show, setShowContact, resetHandler }) {
  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ opacity: [1, 0, 0, 0], y: [0, 120, 120] }}
          transition={{ ease: "easeInOut", duration: 0.5, delay: 0 }}
        >
          <PanelContainer
            component={motion.div}
            animate={{ opacity: [0, 0, 0, 1], y: [120, 120, 0] }}
            transition={{ ease: "easeInOut", duration: 0.5, delay: 1.5 }}
          >
            <TitleBar>
              <Typography variant="h5">Contact Me</Typography>
              <Button
                onClick={() => {
                  resetHandler();
                  setShowContact(false);
                }}
              >
                <span className="text">X</span>
              </Button>
            </TitleBar>
            <Grid container spacing={3} style={{ justifyContent: "center" }}>
              {socials.map((social, index) => (
                <Grid key={index} item xs={12} md={4}>
                  <SingleSocial data={social} index={index} delay={index / 7 + 2} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Typography
                  component={motion.h6}
                  animate={{ scale: [0, 1], opacity: [0, 1] }}
                  transition={{ ease: "easeInOut", duration: 0.5, delay: 2.35 }}
                  variant="h6"
                  style={{ color: "#fcfcfc", textAlign: "center", marginTop: 30 }}
                >
                  Or send me an email - arizhik@outlook.com
                </Typography>
              </Grid>
              {/*    <Grid item xs={12}>
                <ContactForm />
              </Grid> */}
            </Grid>
          </PanelContainer>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const PanelContainer = styled(withTheme(Box))((props) => ({
  backgroundColor: "rgba(0,0,0,0.65)",
  borderRadius: "3rem",
  padding: "35px 40px",
  border: "2px solid rgba(45, 32, 20,0.25)",
  borderTopWidth: "1px",
  borderLeftWidth: "1px",
  position: "relative",
  zIndex: 12,
  width: "100%",
  maxHeight: 1200,
  overflow: "auto",
  maxWidth: 1200,
  margin: "0 auto",
  transition: "transform 0.25s ease, background-color 0.25s ease",
  backdropFilter: "blur(4px)",
  boxShadow:
    "2px 2px 0px rgba(45, 32, 20,0.25), -2px -2px 0px rgba(45, 32, 20,0.25), -2px 2px 0px rgba(45, 32, 20,0.25), 2px -2px 0px rgba(45, 32, 20,0.25)",
  "&::-webkit-scrollbar": {
    width: 6,
    height: 6,
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,0)",
    borderRadius: "2rem",
  },
  [props.theme.breakpoints.down("sm")]: {
    borderRadius: 0,
    padding: "35px 10px",
    position: "absolute",
    top: 0,
    bottom: 0,
    maxHeight: "100vh",
  },
}));

const TitleBar = styled(withTheme(Box))((props) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 30,
  "& h5": {
    color: "#fcfcfc",
    backgroundColor: "rgba(45,32,20,0.75)",
    padding: "20px 40px",
    borderRadius: "3rem",
    [props.theme.breakpoints.down("sm")]: {
      marginLeft: -40,
      padding: "20px 60px 20px 50px",
      borderRadius: "0rem 3rem 3rem 0rem",
    },
  },
  "& button": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 10,
    borderRadius: "100%",
    height: 64,
    width: 64,
    color: "#fcfcfc",
    "& .text": {
      marginBottom: -4,
      marginRight: -2,
    },
  },
}));

export default connect(mapStateToProps, mapDispatchToProps)(ContactPanel);
