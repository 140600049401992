import React from "react";
import { styled, makeStyles } from "@material-ui/styles";
/* import Clouds from "../Assets/Clouds_render_02.png"; */
import Clouds from "../Assets/Clouds_render_new.png";
import Box from "@material-ui/core/Box";

function Terrain() {
  const classes = styles();

  return (
    <>
      <SkyContainer style={{ zIndex: -3 }}>
        <Box className={classes.tile} style={{ background: `url(${Clouds})` }} draggable={false}></Box>
      </SkyContainer>
      {/*       <SkyContainer style={{ zIndex: -4 }}>
        <Box className={classes.tile2} style={{ background: `url(${Clouds})` }}></Box>
      </SkyContainer> */}
    </>
  );
}

const styles = makeStyles((theme) => ({
  "@keyframes animateSky": {
    from: { transform: "translate3d(0,0,0)" },
    to: { transform: "translate3d(-2372px,0,0)" },
  },
  tile: {
    animation: "$animateSky 300s linear infinite",
    backgroundRepeat: "repeat-x",
    width: 7116,
    height: "100vh",
  },
  /*   tile2: {
    animation: "$animateSky 240s linear infinite",
    backgroundRepeat: "repeat-x",
    width: 6944,
    height: "80vh",
    opacity: 0.5,
  }, */
}));

const SkyContainer = styled(Box)({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  zIndex: -3,
  overflow: "hidden",
  userSelect: "none",
  pointerEvents: "none",
});

export default Terrain;
