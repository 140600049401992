import React from "react";
import { styled } from "@material-ui/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";

function SocialButtons() {
  return (
    <ButtonBar>
      <a href="https://www.linkedin.com/in/aaronrizhik/" target="_blank" rel="noreferrer">
        <Button>
          <LinkedInIcon />
        </Button>
      </a>
      <a href="https://github.com/arzhk" target="_blank" rel="noreferrer">
        <Button>
          <GitHubIcon />
        </Button>
      </a>
    </ButtonBar>
  );
}

const ButtonBar = styled(Box)({
  position: "fixed",
  bottom: 0,
  left: 0,
  padding: 20,
  "& button": {
    height: 70,
    width: 70,
    margin: 8,
    borderRadius: "100%",
    /*  backgroundColor: "rgba(45, 32, 20,0.75)", */
    backgroundColor: "rgba(0,0,0,0.65)",
    border: "3px solid rgba(45, 32, 20,0.3)",
    backdropFilter: "blur(8px)",
    "& svg": {
      color: "#fcfcfc",
      height: 45,
      width: 45,
    },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.35)",
    },
  },
});

export default SocialButtons;
